.none {
  display: none;
  transition: 0.3s;
}

.active {
  color: black;
}

.layout {
  padding-inline: 5%;
}

.highlight {
  color: $clr1;
}

.btn-app {
  color: white;
  background-color: $clr1;
  border-color: $clr1;

  a {
    text-decoration: none;
    color: white;
    width: 100%;
    height: 100%;
    display: block;
  }

  &:hover {
    background-color: $clr1;
    opacity: 0.95;
    color: white;
  }
  &:focus {
    background-color: $clr1;
    opacity: 0.95;
    color: white;
  }
  &:active {
    background-color: $clr1;
    opacity: 0.95;
    color: white;
  }

  --bs-btn-disabled-bg: #7f60f3;
  --bs-btn-disabled-border-color: #7f60f3;
}
