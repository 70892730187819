* {
  box-sizing: border-box;
}

body {
  font-family: "Open Sans Condensed", sans-serif;
  margin: 0;
  padding: 0;
  background-color: $clr2;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #212529;
}

h1,
h2,
h3,
h4,
h5,
h6,
p {
  margin: 0;
  padding: 0;
}

ul {
  list-style-type: none;
  margin: 0;
  padding: 0;
}

img {
  vertical-align: middle;
  border-style: none;
  border: none 0;
  max-width: 100%;
}

// btns

.btn-check:checked + .btn,
.btn.active,
.btn.show,
.btn:first-child:active,
:not(.btn-check) + .btn:active {
  color: white;
  background-color: $clr1;
  border-color: $clr1;
}

#dropdown-menu-button {
  &:active,
  &:focus {
    color: black;
    background-color: transparent;
    border-color: transparent;
  }
}

.form-check-input:checked {
  background-color: $clr1;
  border-color: $clr1;
}
