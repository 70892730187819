.graph-carousel-cmp {
  width: 50%;
  margin-top: 50px;
  transform: scale(1.3);
}

.carousel-caption {
  top: 0;
  color: black;
  z-index: -10;
}

.carousel-control-next-icon,
.carousel-control-prev-icon {
  padding: 5px;
  background-color: $clr1;
  border-radius: 50%;
  transform: scale(1.5);
  opacity: 1 !important;
}

.carousel-indicators [data-bs-target] {
  background-color: $clr1;
}

@media (max-width: 768px) {
  .graph-carousel-cmp {
    width: 85%;
    transform: scale(1.1);
  }
}
