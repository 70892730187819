// // SETUP
// @import 'setup/typography';
@import 'setup/variables';

// // BASICS
@import 'basics/helpers';
@import 'basics/base';
// @import 'basics/layout';

@import 'cmps/header';
@import 'cmps/starter';
@import 'cmps/chart';
@import 'cmps/addDay';
@import 'cmps/motivation';
@import 'cmps/intro';
@import 'cmps/userProfile';
@import 'cmps/graphCarousel';
@import 'cmps/blog';
@import 'cmps/blogArticle';
@import 'cmps/contact';

.dropdown-btn{
  height: 100%;
  width: 100%;
  // background-color: red;
  // content: "︙";
  font-size: 1.5rem!important;
  --bs-btn-font-size: 1.5rem!important;
  // position: absolute; 
  // top: 0; 
  // left: 0; 
  // width: 100%;
  // height: 100%; 
}



// body {
//   margin: 0;
//   font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
//     'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
//     sans-serif;
//   -webkit-font-smoothing: antialiased;
//   -moz-osx-font-smoothing: grayscale;
// }

// code {
//   font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
//     monospace;
// }

// .App {
//   text-align: center;
//   height: 100vh;
// }

// .App-logo {
//   height: 40vmin;
//   pointer-events: none;
// }

// @media (prefers-reduced-motion: no-preference) {
//   .App-logo {
//     animation: App-logo-spin infinite 20s linear;
//   }
// }

// .App-header {
//   background-color: #282c34;
//   min-height: 100vh;
//   display: flex;
//   flex-direction: column;
//   align-items: center;
//   justify-content: center;
//   font-size: calc(10px + 2vmin);
//   color: white;
// }

// .App-link {
//   color: #61dafb;
// }

// @keyframes App-logo-spin {
//   from {
//     transform: rotate(0deg);
//   }
//   to {
//     transform: rotate(360deg);
//   }
// }

// .text-left {
//   text-align: left;
// }






