.chart-cmp {
  display: flex;
  z-index: 10;
}



.recharts-surface {
  border: 1.5px $clr1 solid;
  border-radius: 5px;
  -webkit-box-shadow: 3px 3px 2px 1px rgba(100, 87, 87, 0.15);
  -moz-box-shadow: 3px 3px 2px 1px rgba(100, 87, 87, 0.15);
  border-radius: 20px;
}

.line-chart {
  width: 1000px;
}

@media (max-width: 768px) {
  .chart-cmp {
    // display: none
  }
}
